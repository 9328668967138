import { createMuiTheme, responsiveFontSizes, colors } from '@material-ui/core'

export const getTheme = (prefersDarkMode = false) =>
  responsiveFontSizes(
    createMuiTheme({
      palette: {
        primary: {
          main: colors.teal[200],
        },
        secondary: colors.blueGrey,
        type: prefersDarkMode ? 'dark' : 'light',
      },
      typography: {
        h1: {
          fontSize: '4.5rem',
        },
        h3: {
          fontWeight: 300,
        },
      },
    }),
  )
