declare global {
  interface Window {
    gtag?: (command: 'event', name: string, params: unknown) => void
  }
}

export const AnalyticsSetup = () => (
  <>
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
    page_path: window.location.pathname
  });
`,
      }}
    />
  </>
)

export const logEvent = (name: string, params: unknown = {}) => {
  window.gtag?.('event', name, params)
}
