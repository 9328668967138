import React, { useMemo, useEffect } from 'react'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import ErrorPage from 'next/error'
import { useMediaQuery, CssBaseline, ThemeProvider } from '@material-ui/core'

import { getTheme } from '../config/theme'
import { logEvent } from '../utils/analytics'
import Bugsnag from '../utils/bugsnag'

export const reportWebVitals = ({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) => {
  logEvent(name, {
    event_category:
      label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',

    // values must be integers
    value: Math.round(name === 'CLS' ? value * 1000 : value),

    // id unique to current page load
    event_label: id,

    // avoids affecting bounce rate.
    non_interaction: true,
  })
}

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

const App = ({ Component, pageProps }: AppProps) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = useMemo(() => getTheme(prefersDarkMode), [prefersDarkMode])

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={() => <ErrorPage statusCode={500} />}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
